.animation_left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -99;
    animation: animationLeft 7s running infinite;
    animation-timing-function: steps(3, jump-start);
    height: 110%;
}

@keyframes animationLeft {
    from {
        transform: scale(1.01);
    }
    to {
        transform: scale(1.05);
    }
}

.animation_right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -99;
    animation: animationRight 7s infinite;
    animation-play-state: running;
    animation-timing-function: steps(3, jump-start);
    height: 110%;
}

@keyframes animationRight {
    from {
        transform: scale(1.01);
    }
    to {
        transform: scale(1.07);
    }
}

.home_container {
    margin: 100px auto 0px;
}

.logo_b {
    max-width: 353px;
    width: 100%;
    cursor: pointer;
}

.logo_b img {
    width: 100%;
}

.logo_b p {
    color: #293D8E;
    white-space: nowrap;
    font-size: 32px;
    line-height: normal;
    font-family: "Jost" !important;
    margin-top: -10px;
    width: 100%;
    font-weight: 600;
}

.home_container_pic_b {
    margin-top: 25px;
}

.home_container_pic_b img {
    max-width: 293px;
    width: 100%;
}

.home_container_text_b, .home_container_text_b_2 {
    margin: 54px auto 32px;
    max-width: 690px;
    width: 100%;
    text-align: center;

}

.home_container_text_b {
    animation: showText 2s;
}

@keyframes showText {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.home_container_text_b_2 {
    animation: showText2 2s;
}

@keyframes showText2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.talk_us_btn {
    animation: showBtn 3s;
}

.xoski {
    opacity: 0;
}

.xoski.talk_us_btn {
    opacity: 1;
}

@keyframes showBtn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes showBtn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.inputs_block {
    max-width: 608px;
    width: 100%;
    margin: auto;
    animation: inputsAnimation 7s;

}

.send_btn {
    margin-bottom: 48px;
}

@keyframes inputsAnimation {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes inputsAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.inputs_block input {
    max-width: 608px;
    width: 100%;
    border-radius: 59px;
    background: #E5E8F1;
    padding: 14px 30px;
    color: #625e5e;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.023%;
    letter-spacing: 0.2px;
}

.inputs_block input::placeholder {
    color: #A5A5A5;
}

.inputs_block input:first-child {
    margin-bottom: 20px;
}

.send_data_text {
    margin-top: -40px;
    margin-bottom: 24px;
}

@media (max-width: 1900px) {
    .animation_left {
        height: 115%;
    }

    .animation_right {
        height: 115%;
    }
}

@media (max-width: 1280px) {
    .animation_left {
        max-width: 400px;
        width: 100%;
        height: 117%;
    }

    .animation_right {
        max-width: 700px;
        width: 100%;
        height: 117%;
    }
}

@media (max-width: 1024px) {
    .animation_left {
        height: 152%;
    }

    .animation_right {
        height: 152%;
    }
}

@media (max-width: 900px) {
    .animation_left {
        height: 142%;
    }

    .animation_right {
        height: 142%;
    }

    .home_container {
        max-width: 700px;
    }
}

@media (max-width: 912px) {
    .animation_left {
        height: 105%;
    }

    .animation_right {
        height: 105%;
    }
}

@media (max-width: 768px) {
    .home_container {
        margin: 50px auto 0px;
        max-width: 620px;
    }

    .animation_left {
        max-width: 400px;
        width: 100%;
        height: 135%;
    }

    .animation_right {
        max-width: 700px;
        width: 100%;
        height: 135%;
    }

    .logo_b {
        max-width: 267px;
    }

    .logo_b p {
        font-size: 24px;
    }
}

@media (max-width: 540px) {
    .animation_left {
        display: none;
    }

    .animation_right {
        display: none;
    }

    .home_container_text_b {
        margin: 24px auto 16px;
        max-width: 420px;
        width: 100%;
    }

    .logo_b {
        max-width: 210px;
    }

    .logo_b p {
        font-size: 19px;
        font-weight: 700;
    }


    .home_container_pic_b {
        margin-top: 16px;
    }

    .home_container {
        margin-bottom: 48px;
    }

    .send_btn {
        margin-bottom: 0px;
    }

    .send_data_text {
        margin-top: 12px;
        margin-bottom: 24px;
    }
}

@media (max-width: 412px) {
    .home_container_pic_b img {
        max-width: unset;
        width: 100%;
    }

    .home_container_text_b {
        max-width: 320px;
    }

    .home_container {
        margin: 24px auto 48px;
    }

    .inputs_block input {
        padding: 12px 24px;
        font-size: 16px;
        line-height: 80%;
    }
}

