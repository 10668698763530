@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter/Inter-Regular.ttf');
}
@font-face {
  font-family: 'Jost';
  src: url('assets/fonts/Jost/Jost-Regular.ttf');
}

body{
  background: white;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  box-sizing: border-box;
  color: #161B45;
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
}
button:hover {
  transform: scale(1.03);
}

.container {
  max-width: 1232px;
  padding: 0px 16px;
  width: 100%;
  margin: 0 auto;
}

.d_grid {
  display: grid;
  align-items: center;
  justify-items: center;
}
.grid_columns_4fr {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid_columns_3fr {
  grid-template-columns: 1fr 1fr 1fr;
}
.grid_columns_2fr {
  grid-template-columns: 1fr 1fr;
}

.grid_gab {
  grid-gap: 50px;
}
.grid_gab_32{
  grid-gap: 32px;
}
.grid_gab_24{
  grid-gap: 24px;
}
.grid_gab_20{
  grid-gap: 20px;
}

.d_flex {
  display: flex;
}

.fd_column {
  flex-direction: column;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_start {
  justify-content: start;
}

.space_between {
  justify-content: space-between;
}

.align_items_center {
  align-items: center;
}

.align_items_start {
  align-items: flex-start;
}

.align_items_end {
  align-items: flex-end;
}

.f_400 {
  font-weight: 400;
}

.f_500 {
  font-weight: 500;
}

.f_600 {
  font-weight: 600;
}

.f_700 {
  font-weight: 700;
}
.fs_8 {
  font-size: 8px;
  line-height: 12px;
}
.fs_10 {
  font-size: 10px;
  line-height: 15px;
}
.fs_12 {
  font-size: 12px;
  line-height: 18px;
}

.fs_14 {
  font-size: 14px;
  line-height: 20px;
}

.fs_16 {
  font-size: 16px;
  line-height: 19px;
}

.fs_18 {
  font-size: 18px;
  line-height: 27px;
}
.fs_24{
  font-size: 24px;
  line-height: 31px;
}
.fs_32{
  font-size: 32px;
  line-height: 128.023%;
}
.fs_36{
  font-size: 36px;
  line-height: 47px;
}
.fs_46 {
  font-size: 46px;
  line-height: 63px;
}
.margin_top_8{
  margin-top: 8px;
}
.margin_top_16{
  margin-top: 16px;
}
.margin_top_24{
  margin-top: 24px;
}
.margin_top_32{
  margin-top: 32px;
}

.c_white {
  color: #FFFFFF;
}
.c-red {
  color: red;
}
.c_blue{
 color: #2A3E8E;
}
.bc_blue {
  background: #2A3E8E;
}
input{
  background: none;
}
input:focus {
  outline: none;
}

.text_center {
  text-align: center;
}
.text_left {
  text-align: left;
}
.button_full{
  padding: 12px 42px;
  border-radius: 31px;
  background: #2A3E8E;
  color: white;
}

.error {
  color: #293D8E;
  font-size: 16px;
  margin-top: 25px;
  font-weight: 500;
  margin-bottom: -10px;
  text-align: center;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 900px){
  .grid_columns_4fr {
    grid-template-columns: 1fr 1fr ;
  }
}

@media (max-width: 768px) {
  .fs_32{
    font-size: 24px;
    line-height: 31px;
  }
  .fs_24{
    font-size: 18px;
    line-height: 27px;
  }
  .error{
    margin-top: 16px;
    margin-bottom: -18px;
  }
}
@media(max-width: 540px){
  .grid_gab_20{
    grid-gap: 10px;
  }
}
@media (max-width: 500px) {
  .fs_32{
    font-size: 18px;
    line-height: 27px;
  }
  .fs_24{
    font-size: 16px;
    line-height: 19px;
  }
}
@media(max-width: 412px){
  .grid_columns_4fr {
    grid-template-columns: 1fr;
  }
  .margin_top_32 {
    margin-top: 16px;
  }
  .error{
    margin-top: 16px;
    margin-bottom: 0px;
  }
}
